<template>
    <div id="app_template_detail_zj_id">
        <!-- 浙江移动直连-多媒体消息模板详情 -->
        <el-dialog class="cur_dialog" title="" center :visible.sync="dialog_visible" fullscreen destroy-on-close :show-close="false" :modal-append-to-body="false">
            <!-- 头 -->
            <el-row class="dialog_top">
                <span>多媒体消息模板详情</span>
                <i class="el-icon-close dialog_top_close_button" @click="back()" style="font-size:24px"></i>
            </el-row>

            <!-- 体 -->
            <el-row style="margin:10px 0px 0px;padding:0px 15px;background-color: white;">

                <el-row style="overflow: auto;padding-right:10px;" :style="{height: (content_heigth+'px')}">
                    <el-table :data="tableData_base" :show-header="false" style="width: 100%" size="mini">
                        <el-table-column fixed prop="label" width="110" align="center"> </el-table-column>
                        <el-table-column prop="show_value" min-width="200" align="right"> 
                            <template slot-scope="scope">
                                <template v-if="scope.row.name == 'frames'">
                                    <template v-for="item1 in scope.row.show_value">
                                        <div style="overflow: auto;float:left; border:1px solid #dddddd; width: 320px; height:400px; text-align: center; padding-top: 10px; margin: 5px;">
                                            <template v-for="(item2,index) in item1">
                                                <!-- text文本内容，图片1， 视频2，音频3 -->
                                                <template v-if="item2.type =='text'">
                                                    <h3>{{item2.src}}</h3>
                                                </template>
                                                <template v-else-if="item2.type == 1">
                                                    <img :src="item2.src" alt="" style="width:150px; height:250px;">
                                                </template>
                                                <template v-else-if="item2.type == 2">
                                                    <video :src="item2.src" controls class="video" width="98%"></video>
                                                </template>
                                                <template v-else-if="item2.type == 3">
                                                    <audio :src="item2.src" controls="controls" ref="audio">您的浏览器不支持audio标签</audio>
                                                </template>
                                            </template>
                                        </div>
                                    </template>
                                </template>
                                <template v-else>
                                    <span v-html="scope.row.show_value"></span>
                                </template>
                             </template>
                        </el-table-column>
                    </el-table>                    
                </el-row>

            </el-row>

        </el-dialog>
    </div>
</template>
<script>
import API from '../../api/api';
export default {
    components: {
        
    },
    props:{
        prop_change_i:0,
        prop_template_id:""
    },
    watch:{//监听props
        prop_change_i(val){
            //数据清除
            this.dataClear();
            if(val == 0){
                this.dialog_visible = false;//弹出框是否可见 
            }else{
                this.dialog_visible = true;//弹出框是否可见 

                setTimeout(() => {
                    //获取数据
                    this.getData();
                }, 1);
            }
        }
    },
    data(){
        return{
            dialog_visible: false,//弹出框是否可见  false:不可见

            content_width:300,//内容的=宽度
            content_heigth:500,//内容的高度

            tableData_base:[],//模板详细信息数据

        }
    },
    //第二个生命周期函数，在created 中，data 和 methods 都已经被初始化好了！
    created() {
        //初始化设置
        this.initSetUp();
    },
    //方法
    methods: {
        //初始化设置
        initSetUp(){
            let clientWidth = document.body.clientWidth;
            let clientHeight = document.body.clientHeight;
            // console.log("document.body.clientWidth:"+document.body.clientWidth+" "+document.body.clientHeight);
            this.content_width = clientWidth;
            this.content_heigth = (clientHeight - 80);    
        },
         //数据清除
        dataClear(){
            this.tableData_base = this.$options.data().tableData_base;//单个恢复初始化
        },

        //查询
        getData(){
            this.tableData_base = this.$options.data().tableData_base;//单个恢复初始化

           //请求接口
            API.MediaTemplateServlet({
                param: "mediaTemplate",
                template_id: this.prop_template_id,
            }).then((res) => {
                //console.log(res);
                if (res.recode === 0) {

                    var show_value = "";
                    table_obj = {label:"模板id",show_value:res.detail.mmsid,value:res.detail.mmsid,name:"mmsid"};
                    this.tableData_base.push(table_obj);

                    table_obj = {label:"审核结果",show_value:res.detail.state,value:res.detail.state,name:"state"};
                    this.tableData_base.push(table_obj);

                    table_obj = {label:"审核结果描述",show_value:res.detail.msg,value:res.detail.msg,name:"msg"};
                    this.tableData_base.push(table_obj);
                    
                    var table_obj = {label:"标题",show_value:res.detail.title,value:res.detail.title,name:"title"};
                    this.tableData_base.push(table_obj);

                    table_obj = {label:"实际发送企业",show_value:res.detail.user_company_name,value:res.detail.user_company_name,name:"user_company_name"};
                    this.tableData_base.push(table_obj);

                    table_obj = {label:"签名内容",show_value:res.detail.signword,value:res.detail.signword,name:"signword"};
                    this.tableData_base.push(table_obj);

                    table_obj = {label:"服务代码",show_value:res.detail.service_code,value:res.detail.service_code,name:"service_code"};
                    this.tableData_base.push(table_obj);

                    table_obj = {label:"退订信息",show_value:res.detail.is_unsubscribe,value:res.detail.is_unsubscribe,name:"is_unsubscribe"};
                    this.tableData_base.push(table_obj);

                    table_obj = {label:"模板内容",show_value:res.detail.frames,value:res.detail.frames,name:"frames"};
                    this.tableData_base.push(table_obj);
           
                }
                
            });
        },
        //关闭
        back(){
            this.dialog_visible = false;//弹出框是否可见   false:不可见
        },

    }
};

</script>
<style scoped>
/* 增加或编辑弹框中内容距离标题的距离 */
.cur_dialog >>>.el-dialog__body {
    padding: 0px 0px;
}
.cur_dialog >>>.el-dialog__header {
    padding: 0px 0px;
}
.el-carousel__item h3 {
    color: #475669;
    font-size: 18px;
    opacity: 0.75;
    padding-top: 10px;
    margin: 0;
    text-align: center;
}
.el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
} 
.el-carousel__item:nth-child(2n+1) {
    background-color: #d3dce6;
}
</style>